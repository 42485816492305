











import { Vue, Component } from "vue-property-decorator";
import Organize from "@/components/home/Organize.vue";

@Component({
  components: {
    Organize,
  },
})
export default class ProjectBenefit extends Vue {
  get img_src() {
    return this.$i18n.locale == "en"
      ? `http://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/%E6%9D%83%E7%9B%8A_2024/bestech_projectbenefit_en.jpg`
      : `http://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/%E6%9D%83%E7%9B%8A_2024/bestech_projectbenefit_cn.jpg`;
  }
}
